export default function Example() {
  return (
    <div className="mt-32 overflow-hidden sm:mt-40">
      <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Medical Service in Action
            </h2>
            <p className="mt-6 text-xl leading-8 text-gray-600">
              Einblick in unsere Welt: Der Medical Service Krankentransport
              Ravensburg – Ihr vertrauenswürdiger Partner für erstklassige
              Krankentransporte. Entdecken Sie, wie wir mit Hingabe, Modernität
              und Expertise für die Gesundheit und Sicherheit unserer Patienten
              stehen.
            </p>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Unsere engagierten Teams setzen sich leidenschaftlich für Ihre
              Gesundheit ein. Erfahren Sie mehr über unsere Mission und die
              exzellenten Dienstleistungen, die den Medical Service
              Krankentransport Ravensburg auszeichnen.
            </p>
          </div>
          <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
            <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
              <img
                        src={process.env.PUBLIC_URL + "/ms-35.jpg"}
                        alt=""
                className="w-[37rem] rounded-2xl bg-gray-50 object-cover"
              />
            </div>
            <div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
              <div className="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
                <img
                        src={process.env.PUBLIC_URL + "/ms-57.jpg"}
                        alt=""
                  className="w-[24rem] flex-none rounded-2xl bg-gray-50 object-cover"
                />
              </div>
              <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                <img
                        src={process.env.PUBLIC_URL + "/ms-19.jpg"}
                        alt=""
                  className=" w-[37rem] flex-none rounded-2xl bg-gray-50 object-cover"
                />
              </div>
              <div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                <img
                        src={process.env.PUBLIC_URL + "/ms-07.jpg"}
                        alt=""
                  className="w-[24rem] rounded-2xl bg-gray-50 object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
