import axios from "axios";
import { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";
 
export default function Example() {
  const [show, setShow] = useState(false);
  const [notificationSuccess, setNotificationSuccess] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationText, setNotificationText] = useState("");

  //validate form
  const [validEmail, setValidEmail] = useState(true);
  const [validFirstName, setValidFirstName] = useState(true);
  const [validName, setValidName] = useState(true);
  const [validMessage, setValidMessage] = useState(true);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [isLoading, setIsLoading] = useState(false);

  //send request to backend
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const firstName = event.target["first-name"].value;
    const lastName = event.target["last-name"].value;
    const email = event.target.email.value;
    const comment = event.target.comment.value;

    setValidEmail(emailRegex.test(email));
    if (firstName === "") {
      setValidFirstName(false);
    }
    else{
      setValidFirstName(true);
    }
    if (lastName === "") {
      setValidName(false);
    }
    else{
      setValidName(true);
    }
    if (comment === "") {
      setValidMessage(false);
    }
    else{
      setValidMessage(true);
    }

    if (emailRegex.test(email) && firstName !== "" && lastName !== "" && comment !== "") {
      try {
        const response = await axios.post(
          'https://email-service-24nd53uwea-ey.a.run.app/send',
          // "http://127.0.0.1:8080/send",
          {
            name: `${firstName} ${lastName}`,
            email: email,
            body: comment,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Basic bWVkaWNhbHNlcnZpY2U6QUZGR3J6bFQ0dlljOHJNelAyaDdyNEF3ZzdlZFdkOEFTc1A=",
            },
          }
        );
        console.log(response);
        if (response.status === 200) {
          setShow(true);
          setNotificationSuccess(true);
          setNotificationTitle("Erfolgreich versendet!");
          setNotificationText("Wir melden uns schnellstmöglichst bei Ihnen.");
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);

        setShow(true);
        setNotificationSuccess(false);
        setNotificationTitle("Fehler!");
        setNotificationText(error.response.data);
        setIsLoading(false);
      }
    }
  };

  return (
    <div
      className=" mx-auto max-w-7xl px-6 lg:px-8 space-y-10 divide-y divide-gray-900/10"
      id="kontakt"
    >
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        {/* START NOTIFICATION PANEL */}

        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {notificationSuccess ? (
                      <CheckCircleIcon
                        className="h-6 w-6 text-green-400"
                        aria-hidden="true"
                      />
                    ) : (
                      <XCircleIcon
                        className="h-6 w-6 text-red-400"
                        aria-hidden="true"
                      />
                    )}{" "}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      {notificationTitle}{" "}
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      {notificationText}
                    </p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <span className="sr-only">schließen</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
        {/* END NOTIFICATION PANEL */}
      </div>
      <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
        <div className="px-4 sm:px-0">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Kontakt
            </h2>
            <p className="text-lg leading-8 mt-6 text-red-400 font-bold">        
            ACHTUNG: im Notfall wählen Sie bitte die europaweite Notrufnummer 112!      
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Optimieren Sie Ihren Zugang zu erstklassigen Krankentransporten!
              Nutzen Sie unser Kontaktformular, um schnell und unkompliziert mit
              dem Medical Service Krankentransport Ravensburg in Verbindung zu
              treten.
            </p>
          </div>
        </div>

        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
        >
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Vorname{" "}
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    className={`block w-full rounded-md border-0 py-1.5 pr-10 ${
                      validFirstName
                        ? "text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-medicblue-600"
                        : "text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500"
                    } sm:text-sm sm:leading-6`}
                    aria-invalid={!validFirstName}
                    aria-describedby="name-error"
                  />
                </div>
                {!validFirstName && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    Dieses Feld darf nicht leer sein.
                  </p>
                )}
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Nachname
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    className={`block w-full rounded-md border-0 py-1.5 pr-10 ${
                      validName
                        ? "text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-medicblue-600"
                        : "text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500"
                    } sm:text-sm sm:leading-6`}
                    aria-invalid={!validName}
                    aria-describedby="name-error"
                  />
                </div>
                {!validName && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    Dieses Feld darf nicht leer sein.
                  </p>
                )}
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Emailadresse
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className={`block w-full rounded-md border-0 py-1.5 pr-10 ${
                      validEmail
                        ? "text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-medicblue-600"
                        : "text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500"
                    } sm:text-sm sm:leading-6`}
                    aria-invalid={!validEmail}
                    aria-describedby="email-error"
                  />
                </div>
                {!validEmail && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    Ungültige Emailadresse.{" "}
                  </p>
                )}
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="comment"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Nachricht
                </label>
                <div className="mt-2">
                  <textarea
                    rows={4}
                    name="comment"
                    id="comment"
                    className={`block w-full rounded-md border-0 py-1.5 pr-10 ${
                      validMessage
                        ? "text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-medicblue-600"
                        : "text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500"
                    } sm:text-sm sm:leading-6`}
                    aria-invalid={!validMessage}
                    aria-describedby="name-error"
                  />
                </div>
                {!validMessage && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    Dieses Feld darf nicht leer sein.
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <button
              type="submit"
              disabled={isLoading}
              className="rounded-md bg-medicblue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-medicblue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-medicblue-600"
            >
  {isLoading ? (
    <div className="flex items-center">
      <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      Absenden
      </div>
  ) : (
    "Absenden"
  )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
