const faqs = [
  {
    question: "Wie läuft der Transport ab?",
    answer:
      "Sie haben einen Transportschein Ihres Arztes erhalten und benötigen einen Transport sitzend oder liegend?  Über die 0751-19222 buchen Sie die Fahrt, gerne mit dem Zusatzwunsch das wir Sie fahren. Privattransport oder Auslandsrückholung? nehmen Sie Kontakt über unser Kontaktformular auf- wir melden uns umgehend bei Ihnen.",
  },
  {
    question: "In welchen Regionen sind wir tätig?",
    answer:
      "Unser Haupteinzugsgebiet ist der Bodenseekreis, der Landkreis Ravensburg und der Landkreis Sigmaringen. Wir fahren Sie aber auch gerne überregional, z.B. nach Stuttgart, München oder Zürich. Sprechen Sie uns an!",
  },
  {
    question:
      "Was macht den Medical Service Krankentransport Ravensburg besonders?",
    answer:
      "Der Medical Service Krankentransport Ravensburg zeichnet sich durch seine langjährige Erfahrung, modern ausgestattete Fahrzeuge und engagierte Teams aus. Wir bieten sichere und fachgerechte Krankentransporte in Oberschwaben, setzen auf Qualität und stehen für die Gesundheit unserer Patienten.",
  },
  {
    question:
      "Was sollte ich in einem akuten Notfall tun?",
    answer:
      "Bitte wählen Sie die 112 und schildern Sie der Leitstelle den Notfall. Die Leitstelle entscheidet dann, ob ein Rettungswagen oder ein Krankentransportwagen zu Ihnen geschickt wird. Bitte beachten Sie, dass wir als Krankentransportunternehmen nicht für Notfälle zuständig sind.",
  },
];

export default function Example() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:pt-32 lg:px-8 lg:py-40">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-5">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
              Häufig gestellte Fragen
            </h2>
            <p className="mt-4 text-base leading-7 text-gray-600">
              Ihre Frage ist nicht aufgelistet? Kontaktieren sie uns gerne über
              das{" "}
              <a
                href="#kontakt"
                className="font-semibold text-medicblue-600 hover:text-medicblue-500"
              >
                Kontaktformular
              </a>{" "}
            </p>
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-10">
              {faqs.map((faq) => (
                <div key={faq.question}>
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    {faq.answer}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
