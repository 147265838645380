import { CheckCircleIcon } from "@heroicons/react/20/solid";

const benefits = [
  "30 Tage Urlaub",
  "messbare Überstunden und Leistungszulagen",
  "Notfall- KTW mit modernem Equipment",
  "Vollzeit/Teilzeit und 520€ Jobs",
  "Dienstplan nach DEINEN Bedürfnissen (164 Stunden/Monat)",
];
const requirements = [
  "Führerschein der Klasse B",
  "Teamfähigkeit",
  "Freude am Umgang mit Menschen",
  "körperlich und psychisch belastbar",
  "bereit Verantwortung zu übernehmen",
  "Deutsch in Wort und Schrift Niveau B1",

];

export default function Example() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="text-center">
        <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl py-16">
          Aktuell offene Stellen
        </h1>
      </div>
      <div className="relative isolate">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="mx-auto flex max-w-2xl flex-col gap-16 bg-slate-700/5 px-6 py-16 ring-1 ring-slate-700/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
            <img
              className="h-96 w-full flex-none rounded-2xl object-cover shadow-xl lg:aspect-square lg:h-auto lg:max-w-sm"
              src={process.env.PUBLIC_URL + "/ms-68.jpg"}
              alt="Rettungshelfer/Rettungssanitäter m/w/d"
            />
            <div className="w-full flex-auto">
              <h2 className="text-xl font-bold tracking-tight sm:text-2xl">
                Rettungshelfer/Rettungssanitäter m/w/d
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Bereichern Sie unser Team als leidenschaftlicher
                Rettungssanitäter beim Medical Service Krankentransport
                Ravensburg – eine herausfordernde Position, um Leben zu retten
                und Menschen in Not zu helfen.
              </p>
              <div className="grid grid-cols-2 gap-4">
                <div className="col">
                  <h2 className="text-lg my-5 font-bold tracking-tight sm:text-xl">
                    Dein Profil
                  </h2>

                  <ul
                    className=" grid gap-x-8 gap-y-3 text-gray-600 text-base leading-7"
                  >
                    {requirements.map((requirements) => (
                      <li key={requirements} className="flex gap-x-3">
                        <CheckCircleIcon
                          className="h-7 w-5 flex-none"
                          aria-hidden="true"
                        />
                        {requirements}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col">
                  <h2 className="text-lg my-5 font-bold tracking-tight sm:text-xl">
                    Das bieten wir
                  </h2>

                  <ul
                    className=" grid gap-x-8 gap-y-3 text-gray-600 text-base leading-7"
                  >
                    {benefits.map((benefit) => (
                      <li key={benefit} className="flex gap-x-3">
                        <CheckCircleIcon
                          className="h-7 w-5 flex-none"
                          aria-hidden="true"
                        />
                        {benefit}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Du hast Interesse an dieser Position? Schreibe uns gerne direkt
                eine Nachricht über das Formular am Ende der Seite - oder melde
                dich direkt bei uns per Telefon oder per Mail.{" "}
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
              Wir bilden aus! Hast du Interesse an einem Ausbildungsplatz zum Rettungssanitäter- melde dich bei uns! 
              </p>
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
            style={{
              clipPath:
                "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
            }}
          />
        </div>
      </div>
    </div>
  );
}
