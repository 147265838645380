import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";
import Header from "./header";
import Navbar from "./navbar";
import Features from "./features";
import Cta from "./cta";
import Footer from "./footer";
import Contact from "./contact";
import TeamHero from "./team_hero";
import Jobs from "./jobs";
import Images from "./images";
import Datenschutz from "./datenschutz";
import Imprint from "./imprint";
import Faq from "./faq";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header />
              <Features />
              <Images />
              <Cta />
              <Faq />
              <Contact />
            </>
          }
        />
        <Route
          path="/stellenanzeigen"
          element={
            <>
              <TeamHero />
              <Jobs />
              <Contact />
            </>
          }
        />
        <Route path="/datenschutz" element={<Datenschutz />} />
        <Route path="/impressum" element={
        <>
        <Imprint />
        <Contact />
        </>
        } />
      </Routes>
      <Footer />
    </Router>
  </React.StrictMode>
);
