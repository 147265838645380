import { ChevronRightIcon } from "@heroicons/react/20/solid";

export default function Example() {
  return (
    <div className="bg-white">
      <div className="relative isolate overflow-hidden bg-gradient-to-b from-medicblue-100/20">
        <div className="mx-auto max-w-7xl py-12 sm:pb-32 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-40">
          <div className="px-6 lg:px-0 lg:pt-4">
            <div className="mx-auto max-w-2xl">
              <div className="max-w-lg">
                <img
                  className="h-11"
                  src={process.env.PUBLIC_URL + "/logo_blue.png"}
                  alt="Your Company"
                />
                <div className="mt-24 sm:mt-32 lg:mt-16">
                  <a href="/stellenanzeigen" className="inline-flex space-x-6">
                    <span className="rounded-full bg-medicblue-600/10 px-3 py-1 text-sm font-semibold leading-6 text-medicblue-600 ring-1 ring-inset ring-medicblue-600/10 self-center">
                      Aktuell!
                    </span>
                    <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-600">
                      <span>wir suchen Lebensretter*innen</span>
                      <ChevronRightIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </a>
                </div>
                <h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                  Medical Service Krankentransporte
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">

                Wir, Medical Service Ravensburg- Krankentransport GmbH sind Ihr qualifiziertes privates Rettungsdienstunternehmen in der Region Oberschwaben- Bodensee. Unser Ziel ist es nach aktuellen Notfallspezifischen Guidelines beste Patientenversorgung zu garantieren. Wir setzen auf moderne Ausstattung und sehr gut ausgebildetes Personal.
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <a
                    href="#leistungen"
                    className="rounded-md bg-medicblue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-medicblue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-medicblue-600"
                  >
                    Leistungen
                  </a>
                  <a
                    href="#kontakt"
                    className="text-sm font-semibold leading-6 text-gray-900"
                  >
                    Kontakt <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-20 sm:mt-24 md:mx-auto md:max-w-2xl lg:mx-0 lg:mt-0 lg:w-screen">
            <div
              className="absolute inset-y-0 right-1/2 -z-10 -mr-10 w-[200%] skew-x-[-30deg] bg-white shadow-xl shadow-medicblue-600/10 ring-1 ring-medicblue-50 md:-mr-20 lg:-mr-36"
              aria-hidden="true"
            />
            <div className="shadow-lg md:rounded-3xl">
              <div className="bg-medicblue-500 md:[clip-path:inset(0_round_theme(borderRadius.3xl))]">
                <div
                  className="absolute -inset-y-px left-1/2 -z-10 ml-10 w-[200%] skew-x-[-30deg] bg-medicblue-100 opacity-20 ring-1 ring-inset ring-white md:ml-20 lg:ml-36"
                  aria-hidden="true"
                />
                <img
                  src={process.env.PUBLIC_URL + "/ms-50.jpg"}
                  alt="krankenwagen"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>
    </div>
  );
}
