import { HeartIcon } from "@heroicons/react/24/outline";

const navigation = {
    main: [
        { name: 'Leistungen', href: '/#leistungen' },
        { name: 'Kontakt', href: '/#kontakt' },
        { name: 'Stellenanzeigen', href: '/stellenanzeigen' },
        { name: 'Impressum', href: '/impressum' },
        { name: 'Datenschutz', href: '/datenschutz' },
    ],
    social: [
     
    ],
  }
  
  export default function Example() {
    return (
      <footer className="bg-white">
        <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
          <nav className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
            {navigation.main.map((item) => (
              <div key={item.name} className="pb-6">
                <a href={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                  {item.name}
                </a>
              </div>
            ))}
          </nav>
          <div className="mt-10 flex justify-center space-x-10">
            {navigation.social.map((item) => (
              <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <p className="mt-10 text-center text-xs leading-5 text-gray-500">
            &copy; 2023 Medical Service Ravensburg. Alle Rechte vorbehalten.
          </p>
          <p className=" mt-5 text-center text-xs leading-5 text-gray-500">
  Made with <HeartIcon className="h-3 w-3 inline-block align-text-bottom text-gray-500"/> by 
  <a href="https://www.moriz-buehler.de" className="text-gray-600 hover:text-gray-900"> Moriz Bühler</a>
</p>
        </div>
      </footer>
    )
  }
  