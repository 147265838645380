import { BellAlertIcon, MapIcon, StarIcon, BookOpenIcon } from "@heroicons/react/24/outline";

const features = [
  {
    name: "Zuverlässige Krankentransporte",
    description:
      "Ihr zuverlässiger Partner im qualifizierten Krankentransport! Egal ob Transport zur ambulanten Versorgung, Behandlungsfahrten, Verlegungen und Rückholdienste- Wir sind mit kompetentem Personal und moderner Ausstattung für Sie da.",
    href: "#",
    icon: MapIcon,
  },
  {
    name: "Spezialisierte Krankentransportwagen",
    description:
      "Vertrauen Sie auf unsere modernen Fahrzeuge, ausgestattet mit unterbrechungsfreier Sauerstoff-Versorgung und Tragestuhl für eine bedarfsgerechte medizinische Betreuung während des Transports. Durch die umfassende Notfallausrüstung sind wir für jeden Notfall vorbereitet.",
    href: "#",
    icon: StarIcon,
  },
  {
    name: "First Responder",
    description:
      "Als First Responder unterstützen wir in Notfällen die medizinisch-fachliche Erstversorgung bis zum Eintreffen weiterer Rettungskräfte, um schnelle Hilfe und Sicherheit zu gewährleisten.",
    href: "#",
    icon: BellAlertIcon,
  },
  {
    name: "Erste Hilfe Kurse",
    description:
      "Für den nächsten Notfall gut vorbereitet? Betriebliche Ersthelfer, EH für Fahrschüler oder Notfalltraing in Arztpraxen, Pflegeeinrichtungen und Krankenhäusern bieten Ihnen erfahrenen Ausbildern in Zusammenarbeit unsere Tochterfirma Ravensburg erste- Hilfe Ausbildung beste Vorbereitung in Theorie und Praxis um Menschenleben zu retten. Nehmen Sie Kontakt mit un auf",
    href: "#",
    icon: BookOpenIcon,
  },
];

export default function Example() {
  return (
    <div className="bg-white py-12 sm:py-32" id="leistungen">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-medicblue-600">
            Medical Service Ravensburg Krankentransport
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Unsere Leistungen
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Entdecken Sie die umfassenden Leistungen des Medical Service
            Krankentransport Ravensburg. Von sicheren Krankentransporten bis hin
            zu modern ausgestatteten Fahrzeugen – wir setzen auf höchste
            Qualität und Zuverlässigkeit für Ihre medizinischen Bedürfnisse.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-medicblue-600">
                    <feature.icon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
