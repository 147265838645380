export default function Example() {
  return (
    <div className="bg-white px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <p className="text-base font-semibold leading-7 text-indigo-600">
          Medical Service Ravensburg
        </p>
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Impressum
        </h1>
        <p className="mt-6 text-xl leading-8">
          Diese Webseite verwendet Vorlangen von{" "}
          <a href="https://tailwindui.com/">TailwindUI</a>. Diese wurden mit
          einer einsprechenden Lizenz erworben. Bei weiteren Rückfragen melden
          Sie sich gerne über das Kontaktformular.
        </p>
        <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
          Weitere Angaben gemäß § 5 TMG
        </h2>
        <div className="mt-10 max-w-2xl">
          <ul>
            <li>Medical Service Krankentransport Ravensburg GmbH</li>
            <li>Damaschkeweg 1, 88213 Ravensburg</li>
          </ul>
          <p className="mt-8">Vertreten durch: Steffen Schütt</p>
          <p className="mt-6">
            Kontakt:
          </p>
          <ul>
            <li>Telefon: +49 1575 7080102</li>
            <li>Email: info@msr-krankentransport.de</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
